import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import MainContainer from "../components/MainContainer";
import { styled, useTheme } from "@mui/material";
import { fetchDataShield } from "../api/getRelayData";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import InfoToolTip from "../components/InfoToolTip";
import InfoToolTipMobile from "../components/InfoToolTipMobile";
// import { useShieldData } from "../context/ShieldData";

const boxStyle = {
  padding: "2rem",
  color: "#fff",
  backgroundColor: "#0A121E",
  borderRadius: 4,
  border: "1px solid #007275",
  marginBottom: "1rem",
  boxShadow: "0px 0px 1px 0px #d4d4d4",
};

const CustomTextField = styled(TextField)({
  "& label": {
    color: "#595959",
  },
  "& label.Mui-focused": {
    color: "#595959",
  },
  "& label.Mui-disabled": {
    color: "#595959",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)",
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aaa",
    },
  },
  "& .MuiInputBase-input": {
    color: "white",
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#595959",
  },
});

const FamilySettingsPage = ({ openSettings, setOpenSettings }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [fingerprintList, setFingerprintList] = useState([]);
  const [currentFP, setCurrentFP] = useState("");
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [applyDialogOpen, setApplyDialogOpen] = useState(false);
  const [newFingerprint, setNewFingerprint] = useState("");
  const isSmallScreen = useMediaQuery("(max-width:1250px)");
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedFingerprintIndex, setSelectedFingerprintIndex] =
    useState(null);
  const [error, setError] = useState("");
  const [isSelecting, setIsSelecting] = useState(false);
  const [dragStartIndex, setDragStartIndex] = useState(null);
  const [selectedFingerprints, setSelectedFingerprints] = useState(new Set());
  const [deleteConfirmDialogOpen, setDeleteConfirmDialogOpen] = useState(false);
  const [singleDeleteDialogOpen, setSingleDeleteDialogOpen] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null); // Index of the fingerprint to delete
  const [checkPassword, setCheckPassword] = useState(false);
  // const { shieldData, setShieldData } = useShieldData();

  useEffect(() => {
    const extractData = async () => {
      let extractedData = await fetchDataShield();
      setCurrentFP(extractedData["SHIELD_fngrprnt"]);
      if (extractedData["Fingerprints"][0] === "") {
        // console.log(extractedData["Fingerprints"][0]);
        setFingerprintList([]);
      } else {
        setFingerprintList(extractedData["Fingerprints"]);
      }
    };

    extractData();
  }, []);

  useEffect(() => {
    const extractData = async () => {
      let extractedData = await fetchDataShield();
      // Assuming extractedData has the required keys
      setCheckPassword(
        extractedData["Settings"]["ShieldSetting_02"] === "" ? false : true
      );
      extractData();
    };
  }, []);

  const handleOpenSingleDeleteDialog = (index) => {
    setDeleteIndex(index);
    setSingleDeleteDialogOpen(true);
  };

  const handleCloseSingleDeleteDialog = () => {
    setSingleDeleteDialogOpen(false);
    setDeleteIndex(null); // Reset the deletion index
  };

  const handleOpenDeleteConfirmDialog = () => {
    setDeleteConfirmDialogOpen(true);
  };

  const handleCloseDeleteConfirmDialog = () => {
    setDeleteConfirmDialogOpen(false);
  };

  const handleMouseDown = (index) => (event) => {
    if (event.target.closest(".action-button")) {
      // If the event is from an action button, do nothing
      return;
    }
    event.preventDefault(); // Prevent default text selection
    setIsSelecting(true);
    setDragStartIndex(index);
    toggleFingerprintSelection(index);
  };

  const handleMouseOver = (index) => (event) => {
    if (isSelecting) {
      toggleFingerprintSelection(index);
    }
  };

  const handleMouseUp = () => {
    setIsSelecting(false);
  };

  const toggleFingerprintSelection = (index) => {
    const newSelection = new Set(selectedFingerprints);
    if (newSelection.has(index)) {
      newSelection.delete(index);
    } else {
      newSelection.add(index);
    }
    setSelectedFingerprints(newSelection);
  };

  // Call this in a useEffect to handle global mouse up events
  useEffect(() => {
    const stopSelecting = () => {
      if (isSelecting) {
        setIsSelecting(false);
      }
    };

    document.addEventListener("mouseup", stopSelecting);
    return () => {
      document.removeEventListener("mouseup", stopSelecting);
    };
  }, [isSelecting]);

  const clearSelection = () => {
    setSelectedFingerprints(new Set());
  };

  const handleAddFingerprint = () => {
    setNewFingerprint("");
    setSelectedFingerprintIndex(null);
    setAddDialogOpen(true);
  };

  const handleSaveFingerprint = async (e) => {
    e.preventDefault();

    const isValidFingerprint = /^[a-fA-F0-9]{40}$/.test(newFingerprint);

    if (isValidFingerprint) {
      if (fingerprintList.length >= 90 && selectedFingerprintIndex === null) {
        setError("You cannot add more than 90 fingerprints.");
        return;
      }
      let newList;
      if (selectedFingerprintIndex !== null) {
        newList = [...fingerprintList];
        newList[selectedFingerprintIndex] = newFingerprint;
        setFingerprintList(newList);
      } else {
        newList = [...fingerprintList, newFingerprint];
        setFingerprintList(newList);
      }
      try {
        const list = await saveFingerprintList(newList);
        const configResponse = await axios.get(
          `./ZZ_shieldajax.php?rev=1&q=10&${list.toString()}`
        );
      } catch (error) {
        console.error("Error:", error);
        if (error.response) {
          console.error("Error:", error.response.data);
          if (error.response.status === 401) {
            navigate("/");
          } else {
            // Handle other errors
            throw error;
          }
        } else if (error.request) {
          console.error("Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      }

      setNewFingerprint("");
      setAddDialogOpen(false);
      setSelectedFingerprintIndex(null);
      setError(""); // Clear any previous errors
    } else {
      if (!newFingerprint || newFingerprint.length !== 40) {
        setError("Fingerprint length must be 40 characters");
      } else {
        setError("Invalid Hex String");
      }
    }
  };

  const handleCancel = () => {
    setAddDialogOpen(false);
    setSelectedFingerprintIndex(null);
    setError(""); // Clear any previous errors
  };

  const handleDialogClose = () => {
    setApplyDialogOpen(false);
  };

  const handleDeleteFingerprint = async () => {
    const newList = [...fingerprintList];
    if (deleteIndex !== null) {
      newList.splice(deleteIndex, 1); // Remove the fingerprint
      setFingerprintList(newList); // Update the state
      handleCloseSingleDeleteDialog(); // Close the dialog
      try {
        const list = await saveFingerprintList(newList);
        const configResponse = await axios.get(
          `./ZZ_shieldajax.php?rev=1&q=10&${list.toString()}`
        );
      } catch (error) {
        console.error("Error:", error);
        if (error.response) {
          console.error("Error:", error.response.data);
          if (error.response.status === 401) {
            navigate("/");
          } else {
            throw error;
          }
        } else if (error.request) {
          console.error("Error:", error.request);
        } else {
          console.error("Error:", error.message);
        }
      }
    }
  };

  const handleDeleteSelectedFingerprints = async () => {
    const newList = fingerprintList.filter(
      (_, index) => !selectedFingerprints.has(index)
    );
    setFingerprintList(newList);
    setSelectedFingerprints(new Set()); // Clear selection after deletion
    handleCloseDeleteConfirmDialog();

    try {
      const list = await saveFingerprintList(newList);
      const configResponse = await axios.get(
        `./ZZ_shieldajax.php?rev=1&q=10&${list.toString()}`
      );
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          navigate("/");
        } else {
          throw error;
        }
      } else if (error.request) {
        console.error("Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleFingerprintClick = (index, fingerprint) => {
    setSelectedFingerprintIndex(index);
    setNewFingerprint(fingerprint);
    setAddDialogOpen(true);
  };

  const handleApplyClick = () => {
    setApplyDialogOpen(true);
  };

  const handleReboot = async () => {
    try {
      const response = await axios.get("./ZZ_shieldajax.php", {
        params: {
          rev: "1",
          q: "1",
        },
      });

      if (checkPassword) {
        try {
          const logout = await axios.get(`./ZZ_logout.php`);
          localStorage.removeItem('cacheCleared');
          navigate("/");
        } catch (error) {
          // Handle any errors that occur during logout
          setError("Something went wrong. Please try again.");
        }
      }
      window.location.reload();
    } catch (error) {
      console.error("Reboot failed:", error);
    }
  };

  const handleSubmit = async () => {
    let newList = fingerprintList;

    try {
      const list = await saveFingerprintList(newList);
      const configResponse = await axios.get(
        `./ZZ_shieldajax.php?rev=1&q=10&reboot=1&${list.toString()}`
      );
      setApplyDialogOpen(false);
      setTimeout(() => {
        handleReboot();
      }, 1000);
    } catch (error) {
      console.error("Error:", error);
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          navigate("/");
        } else {
          throw error;
        }
      } else if (error.request) {
        console.error("Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const saveFingerprintList = async (newList) => {
    if (currentFP && newList.length > 0 && !newList.includes(currentFP)) {
      newList.unshift(currentFP);
    }
    if (newList.includes(currentFP) && newList.length === 1) {
      newList = [];
    }
    const fingerprintString = newList.join(",");
    let params = new URLSearchParams();
    params.append("fingerprints", fingerprintString);
    return params;
  };

  return (
    <MainContainer
      title="My Dashboard"
      openSettings={openSettings}
      setOpenSettings={setOpenSettings}
    >
      <Dialog
        open={applyDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            // Targeting the Dialog's Paper component for the background
            backgroundColor: "#0A121E", // Example darker color; replace with your actual color
            color: "#fff", // Making text color white
            borderRadius: 2, // Optional: Adding a border radius
            border: "1px solid #007275", // Optional: Adding a border
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Save Changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#fff" }}
          >
            Saving changes requires a reboot to apply. Do you want to proceed with 
            saving settings?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: "#03BDC5" }}>
            Cancel
          </Button>
          <Button onClick={handleDialogClose} sx={{ color: "#03BDC5" }} autoFocus>
            Save
          </Button>
          {/* <Button onClick={handleSubmit} sx={{ color: "#03BDC5" }} autoFocus>
            Apply and Reboot
          </Button> */}
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteConfirmDialogOpen}
        onClose={handleCloseDeleteConfirmDialog}
        aria-labelledby="delete-all-confirmation-title"
        aria-describedby="delete-all-confirmation-description"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#0A121E", // Dark background
            color: "#fff", // White text color
            borderRadius: 2, // Rounded corners
            border: "1px solid #007275", // Cyan border
          },
        }}
      >
        <DialogTitle id="delete-all-confirmation-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="delete-all-confirmation-description"
            sx={{ color: "#fff" }}
          >
            Would you like to delete {selectedFingerprints.size} selected
            {selectedFingerprints.size > 1 ? " fingerprints?" : " fingerprint?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseDeleteConfirmDialog}
            sx={{ color: "#03BDC5" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteSelectedFingerprints}
            sx={{ color: "#03BDC5" }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={singleDeleteDialogOpen} // State to control the visibility of this dialog
        onClose={handleCloseSingleDeleteDialog} // Function to close the dialog
        aria-labelledby="single-delete-dialog-title"
        aria-describedby="single-delete-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#0A121E", // Dark background
            color: "#fff", // White text color
            borderRadius: 2, // Rounded corners
            border: "1px solid #007275", // Cyan border
          },
        }}
      >
        <DialogTitle id="single-delete-dialog-title">
          {"Confirm Deletion"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="single-delete-dialog-description"
            sx={{ color: "#fff" }}
          >
            Are you sure you want to delete this fingerprint?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseSingleDeleteDialog}
            sx={{ color: "#03BDC5" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteFingerprint(deleteIndex)}
            sx={{ color: "#03BDC5" }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ width: "100%" }}>
        {/* Header */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#ffffff" }}
            marginBottom={0}
          >
            Relay Family
          </Typography>

          {!isMobileScreen && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ bgcolor: "#03BDC5", height: "40px", width: "180px" }}
                onClick={handleAddFingerprint}
                disabled={currentFP === "UNKNOWN"}
              >
                Add Fingerprint
              </Button>

              <>
                {selectedFingerprints.size > 0 && (
                  <Button
                    variant="contained"
                    onClick={handleOpenDeleteConfirmDialog}
                    sx={{
                      bgcolor: "red",
                      marginLeft: "1rem",
                      height: "40px",
                      width: "150px",
                      lineHeight: "1.1",
                    }}
                  >
                    Delete Selected
                  </Button>
                )}
              </>

              {/* <Button
                variant="contained"
                onClick={handleApplyClick}
                sx={{
                  bgcolor: "#03BDC5",
                  marginLeft: "1rem",
                  height: "40px",
                  width: "120px",
                }}
              >
                Apply
              </Button> */}
            </Box>
          )}
        </Box>

        {isMobileScreen && (
          <Box
            sx={{
              position: "fixed",
              zIndex: 2,
              bottom: 16,
              left: 0,
              right: 0,
              margin: "auto",
              width: "auto",
              maxWidth: "fit-content", // Prevents the box from touching the viewport edges
              backgroundColor: "#0a121e",
              borderRadius: 2,
              boxShadow: 3,
              border: 1,
              // p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                sx={{ bgcolor: "#03BDC5", height: "40px", width: "180px" }}
                onClick={handleAddFingerprint}
                disabled={currentFP === "UNKNOWN"}
              >
                Add Fingerprint
              </Button>

              <>
                {selectedFingerprints.size > 0 && (
                  <Button
                    variant="contained"
                    onClick={handleOpenDeleteConfirmDialog}
                    sx={{
                      bgcolor: "red",
                      marginLeft: "1rem",
                      height: "40px",
                      width: "150px",
                    }}
                  >
                    Delete Selected
                  </Button>
                )}
              </>

              {/* <Button
                variant="contained"
                onClick={handleApplyClick}
                sx={{
                  bgcolor: "#03BDC5",
                  marginLeft: "1rem",
                  height: "40px",
                  width: "120px",
                }}
              >
                Apply
              </Button> */}
            </Box>
          </Box>
        )}

        {/* Subtitle */}
        <Typography
  variant="subtitle1"
  gutterBottom
  style={{ color: "#aaa" }}
  marginBottom={2}
>
  Edit your relay family here.
  {isMobileScreen ? (
    <InfoToolTipMobile tooltipContent="If you run more than one relay, the MyFamily option on each relay must list all other relays." />
  ) : (
    <InfoToolTip tooltipContent="If you run more than one relay, the MyFamily option on each relay must list all other relays." />
  )}
  <br />
  {currentFP === "UNKNOWN" && (
    <span>You must turn the relay on to add fingerprints</span>
  )}
</Typography>

        {currentFP !== "UNKNOWN" && fingerprintList.length > 1 && (
          <Paper style={boxStyle}>
            <Typography variant="h5" marginBottom={3}>
              Fingerprints
            </Typography>
            <Box>
              <React.Fragment>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  style={{
                    backgroundColor: "inherit",
                    padding: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography>{currentFP}</Typography>
                </Box>
                <div
                  style={{
                    borderBottom: "1px solid #03BDC5",
                    width: "100%",
                    marginBottom: "10px",
                  }}
                ></div>
              </React.Fragment>
              {fingerprintList.map((fingerprint, index) => {
                if (currentFP !== "UNKNOWN" && index !== 0) {
                  return (
                    <React.Fragment key={index}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                          backgroundColor: selectedFingerprints.has(index)
                            ? "#005858"
                            : "inherit",
                          padding: "10px",
                          marginBottom: "10px",
                          cursor: "pointer",
                        }}
                        onMouseDown={handleMouseDown(index)}
                        onMouseOver={handleMouseOver(index)}
                        onMouseUp={handleMouseUp}
                      >
                        <Typography>{fingerprint}</Typography>
                        <Box>
                          <Button
                            className="action-button" // Class to prevent selection
                            onClick={(event) => {
                              handleFingerprintClick(index, fingerprint);
                            }}
                            sx={{ color: "#03BDC5" }}
                          >
                            Edit
                          </Button>
                          <Button
                            className="action-button" // Class to prevent selection
                            onClick={(event) => {
                              // event.preventDefault();
                              // event.stopPropagation(); // Stop propagation to prevent selection toggle
                              handleOpenSingleDeleteDialog(index);
                            }}
                            sx={{ color: "#03BDC5" }}
                          >
                            Delete
                          </Button>
                        </Box>
                      </Box>
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </Box>
          </Paper>
        )}
        <Dialog
          open={addDialogOpen}
          onClose={() => setAddDialogOpen(false)}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#0A121E",
              color: "#fff",
              borderRadius: 2,
              border: "1px solid #007275",
              width: "80%",
              display: "flex",
              flexDirection: "column",
            },
          }}
          PaperProps={{ tabIndex: -1 }}
          autoFocus={false}
        >
          <DialogTitle
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            {selectedFingerprintIndex !== null ? "Edit" : "Add"} Fingerprint
            <Box>
              <Button
                onClick={handleCancel}
                sx={{ color: "#03BDC5", marginRight: "0.5rem" }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleSaveFingerprint}
                sx={{ color: "#03BDC5" }}
              >
                Save
              </Button>
            </Box>
          </DialogTitle>
          <DialogContent
            sx={{
              "& .MuiFormControl-root": {
                width: "100%",
                marginBottom: "0.5rem",
                marginTop: "0.5rem",
              },
            }}
          >
            <CustomTextField
              label="Fingerprint"
              variant="outlined"
              value={newFingerprint}
              onChange={(e) => setNewFingerprint(e.target.value)}
              inputProps={{ maxLength: 40 }} // Add maxLength attribute
              autoFocus
              helperText={`${newFingerprint.length}/40`}
              FormHelperTextProps={{
                sx: {
                  color: newFingerprint.length === 40 ? "#03BDC5" : "red",
                }, // Color changes based on validity
              }}
            />
            {error && (
              <Typography
                variant="body2"
                sx={{ color: "red", marginTop: "0.5rem" }}
              >
                {error}
              </Typography>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </MainContainer>
  );
};

export default FamilySettingsPage;
