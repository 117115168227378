import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const InfoToolTip = ({ tooltipContent }) => {
  return (
    <Tooltip title={tooltipContent}>
      <IconButton size="small">
        <InfoIcon sx={{ color: '#aaa', fontSize: '16px' }} />
      </IconButton>
    </Tooltip>
  );
};

export default InfoToolTip;
