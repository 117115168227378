import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';

const ShieldDataContext = createContext();

export const useShieldData = () => useContext(ShieldDataContext);

export const ShieldDataProvider = ({ children }) => {
  const [shieldData, setShieldData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sessionValid, setSessionValid] = useState(false); // State to track session validity

  const fetchDataShield = async () => {
    try {
      const response = await axios.get('./ZZ_shieldajax.php?rev=1&q=7');
      setSessionValid(true);

      // console.log(sessionValid);
      const extractedData = response.data;

      setShieldData(extractedData);
      setError(null);
      setIsLoading(false);
      return;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setSessionValid(false);
        return;
      }
      
        console.error('Error fetching shield data:', error);
        setError(error);
        setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDataShield(); // Fetch data initially
    const intervalId = setInterval(fetchDataShield, 250); // Polling every second

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return (
    <ShieldDataContext.Provider value={{ shieldData, isLoading, error, sessionValid }}>
      {children}
    </ShieldDataContext.Provider>
  );
};
