import React, { useState } from 'react';
import { Typography, Box, IconButton, Popover } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const CustomInfoItem = ({ label, value }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" padding={'5px'}>
      <Box display="flex" alignItems="center">
        <Typography variant="subtitle2" style={{ color: '#fff', marginRight: 8, marginLeft: 20, maxWidth: '40%' }}>
          {label}:
        </Typography>
        <IconButton
          size="small"
          sx={{ mt: '-3px' }}
          aria-describedby={id}
          onClick={handleClick}
        >
          <InfoIcon sx={{ color: '#aaa', fontSize: '16px', ml: 2 }} />
        </IconButton>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 2 }}>
            The ORPort is the port where a relay listens for incoming connections. If you see 'ORPort Unreachable', it means you might need to adjust your router settings.
          </Typography>
        </Popover>
      </Box>
      <Typography variant="h6" style={{ color: '#03BDC5', fontSize: '18px', marginRight: 21, textAlign: 'right', maxWidth: '55%' }}>
        {value}
      </Typography>
    </Box>
  );
};

export default CustomInfoItem;
