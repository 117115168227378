import React from 'react';
import { Typography, Box, IconButton, Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const CustomInfoItem = ({ label, value }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" padding={'5px'}>
      <Box display="flex">
        <Typography variant="subtitle2" style={{ color: '#fff', marginRight: 8, marginLeft: 20, maxWidth: '40%' }}>
          {label}:
        </Typography>
        <Tooltip title="The ORPort is the port where a relay listens for incoming connections. If you see 'ORPort Unreachable', it means you might need to adjust your router settings">
          <IconButton size="small" sx={{ mt: '-3px' }}> {/* Using the size prop of IconButton */}
            <InfoIcon sx={{ color: '#aaa', fontSize: '16px', ml: 2 }} /> {/* Adjusting fontSize directly */}
          </IconButton>
        </Tooltip>
      </Box>
      <Typography variant="h6" style={{ color: '#03BDC5', fontSize: '18px', marginRight: 21, textAlign: 'right', maxWidth: '55%' }}>
        {value}
      </Typography>
    </Box>
  );
};

export default CustomInfoItem;
