import React from "react";
import { Typography, Box, Button } from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";

// Custom component for label and value
const InfoItemCopyable = ({ label, value, blur }) => {
  // Extracting first six characters
  const firstFive = value.slice(0, 5);

  // Extracting last Five characters
  const lastFive = value.slice(-5);

  // Creating the shortened value
  const shortenedValue = `${firstFive}...${lastFive}`;

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={"5px"}
    >
      <Typography
        variant="subtitle2"
        style={{ color: "#fff", marginLeft: 20, maxWidth: "30%" }}
      >
        {label}:
      </Typography>
      <Box style={{ display: "flex", alignItems: "center", maxWidth: "70%" }}>
        <Typography
          variant="h6"
          style={{
            color: "#03BDC5",
            fontSize: "18px",
            marginRight: value === "UNKNOWN" ? 21 : 10,
            textAlign: "right",
            filter: blur ? "blur(10px)" : "none",
          }}
        >
          {value === "UNKNOWN" ? value : shortenedValue}
        </Typography>
        {value !== "UNKNOWN" && (
          <CopyToClipboard text={value}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ height: "28px", color: "#03BDC5", marginRight: 2 }}
            >
              Copy
            </Button>
          </CopyToClipboard>
        )}
      </Box>
    </Box>
  );
};

export default InfoItemCopyable;
