import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  Button,
  Box,
  ListItemText,
  Collapse,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import DevicesIcon from "@mui/icons-material/Devices";
import GroupIcon from "@mui/icons-material/Group";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import WifiIcon from "@mui/icons-material/Wifi";
import PublicIcon from "@mui/icons-material/Public";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import UndoIcon from "@mui/icons-material/Undo";
import BuildIcon from "@mui/icons-material/Tune";
import { keyframes } from "@mui/system";
import SettingsIcon from "@mui/icons-material/Settings";
import axios from "axios";
import { useLocation } from "react-router-dom";
import LedDisplay from "./LEDDisplay";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { styled } from "@mui/material";
import { fetchData } from "../api/getRelayData";
import { useShieldData } from "../context/ShieldData";
import { useTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

const drawerWidth = "19%";

const flashAnimation = keyframes`
  0%, 100% { 
    background-color: red; 
    color: white; 
  }
  50% { 
    background-color: transparent; 
    color: red;
  }
`;

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Slightly translucent overlay
  zIndex: 10, // Ensure it covers the children
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#fff',
});

const MainContainer = ({ title, children, openSettings, setOpenSettings }) => {
  const location = useLocation();
  const { shieldData, isLoading, error, sessionValid } = useShieldData();
  const [time, setNewTime] = useState("00:00:00");
  const [status, setStatus] = useState("");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery("(max-width:1100px)");
  const checkButtonArrangement = useMediaQuery("(max-width:1374px)");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [allstates, setAllStates] = useState([]);
  const [openInterface, setOpenInterface] = useState(false);
  const [openRelay, setOpenRelay] = useState(false);
  const [openSystem, setOpenSystem] = useState(false);
  const [sessionExpiredDialogOpen, setSessionExpiredDialogOpen] =
    useState(false);
  const navigate = useNavigate();
  const [rebootDialogOpen, setRebootDialogOpen] = useState(false);
  const [flash, setFlash] = useState(false);
  const [checkNewPassword, setCheckNewPassword] = useState(false);

  useEffect(() => {
    if (location.pathname.startsWith("/network-settings")) {
      setOpenInterface(true);
    } else if (
      location.pathname.startsWith("/relay-") ||
      location.pathname.startsWith("/proxy")
    ) {
      setOpenRelay(true);
    } else if (
      location.pathname.startsWith("/password-settings") ||
      location.pathname.startsWith("/logs")
    ) {
      setOpenSystem(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (shieldData) {
      setTimeout(() => {
        //
        setCheckNewPassword(shieldData["Settings"]["ShieldSetting_02"] === "" ? false : true)

      }, 200); // Delay set to 1 second
      
    } else if (error) {
      console.log(error);
    }
  }, [shieldData]); 

  useEffect(() => {
    if (shieldData) {
      setTimeout(() => {
        setFlash(shieldData["unsaved_settings"]);
        setCheckNewPassword(shieldData["Settings"]["ShieldSetting_02"] === "" ? false : true)
      }, 1000); // Delay set to 1 second
      
    } else if (error) {
      console.log(error);
    }
  }, [shieldData["unsaved_settings"], error]); // Add dependencies array if shieldData and error are dependencies of this effect

  const handleUndo = async (e) => {
    try {
      const response = await axios.get(`./ZZ_shieldajax.php?rev=1&q=9`);
      window.location.reload();
    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          navigate("/");
        } else {
          throw error;
        }
      } else if (error.request) {
        console.error("Error:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };

  const handleSettingsClick = () => {
    setOpenSettings(!openSettings);
  };

  const handleOuterTab = () => {
    setOpenSettings(false);
  };

  const handleInnerTab = () => {
    setOpenSettings(true);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleRebootDialogOpen = () => {
    setRebootDialogOpen(true);
  };

  const handleRebootDialogClose = () => {
    setRebootDialogOpen(false);
  };

  const handleInterfaceClick = () => {
    setOpenInterface(!openInterface);
  };

  const handleRelayClick = () => {
    setOpenRelay(!openRelay);
  };

  const handleSystemClick = () => {
    setOpenSystem(!openSystem);
  };

  const handleReboot = async () => {
    
    try {
      const res = await axios.get(`./ZZ_shieldajax.php?rev=1&q=12`);
      handleRebootDialogClose()
      try {
        const response = await axios.get("./ZZ_shieldajax.php", {
          params: {
            rev: "1",
            q: "1",
          },
        });
        if (checkNewPassword) {
          try {
            const logout = await axios.get(`./ZZ_logout.php`);
            localStorage.removeItem('cacheCleared');
            navigate("/");
          } catch (error) {
          }
        }
        // window.location.reload();
      } catch (error) {
        console.error("Reboot failed:", error);
      }
    } catch (error) {
      console.error("failed");
    }
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (!sessionValid) {
        setSessionExpiredDialogOpen(true);
      }
    }, 2000); // Delay the session validity check by 1 second

    return () => clearTimeout(timeoutId);
  }, [sessionValid, navigate]);

  const handleDialogClose = () => {
    setSessionExpiredDialogOpen(false);
    navigate("/"); // Navigate to the login page
  };

  useEffect(() => {
    const navigatedFromRoot = location.state?.fromRootPage;

    if (!isLoading && !error) {
      setStatus(shieldData["SHIELD_state"]);
      setNewTime(shieldData["SHIELD_now_time"]);
      setAllStates(shieldData["SHIELD_allstates"]);
    }

    // if (
    //   shieldData["SHIELD_state"] !== status &&
    //   status !== "" &&
    //   !(
    //     status === "Settings saved" && shieldData["SHIELD_state"] === "Running"
    //   ) &&
    //   shieldData["SHIELD_state"] !== "Settings saved"
    // ) {
    //   window.location.reload();
    // }
  }, [shieldData, isLoading, error, status]);

  const iconColor = "#03BDC5";

  return (
    <div style={{ display: "flex" }}>
      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={isSmallScreen ? drawerOpen : true}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        style={{ width: isSmallScreen ? "270px" : drawerWidth, flexShrink: 0 }}
        PaperProps={{
          style: {
            width: isSmallScreen ? "270px" : drawerWidth,
            backgroundColor: "#0A121E",
            padding: "2rem 0rem 2rem 2rem",
            borderRight: `1px solid rgba(2, 190, 207, 0.2)`,
            color: "white",
          },
        }}
      >
        <Toolbar
      sx={{
        marginBottom: "2rem",
        borderRight: `1px solid rgba(2, 190, 207, 0.2)`,
      }}
    >
      {/* TODO: CHeck this */}
      <LedDisplay status={status} allstates={allstates} /> 
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start', marginTop: 1 }}>
        <Box
          component="img"
          sx={{
            height: 32,
            width: 122,
          }}
          alt="Anyone"
          src="../logos/anyone.png" 
        />
        <Typography
          variant="subtitle2"
          gutterBottom
          style={{ color: "#aaa" }}
          sx={{ marginTop: 0 }}
        >
          {time}
        </Typography>
      </Box>
    </Toolbar>
        <List sx={{ paddingRight: "1.5rem" }}>
          <ListItemButton
            component={Link}
            to="/home"
            sx={{
              color:
                location.pathname === "/home"
                  ? "#03BDC5"
                  : "rgba(2, 190, 207, 0.7)",
              fontSize: "18px",
              ".MuiListItemIcon-root": {
                color:
                  location.pathname === "/home"
                    ? "#03BDC5"
                    : "rgba(2, 190, 207, 0.7)",
              },
            }}
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>

          <ListItemButton
            onClick={handleInterfaceClick}
            sx={{
              color: location.pathname.startsWith("/network-settings")
                ? "#03BDC5"
                : "rgba(2, 190, 207, 0.7)",
              fontSize: "18px",
              ".MuiListItemIcon-root": {
                color: location.pathname.startsWith("/network-settings")
                  ? "#03BDC5"
                  : "rgba(2, 190, 207, 0.7)",
              },
            }}
          >
            <ListItemIcon>
              <DevicesIcon />
            </ListItemIcon>
            <ListItemText primary="Interface" />
            {openInterface ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openInterface} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{
                  pl: 4,
                  color:
                    location.pathname === "/network-settings"
                      ? "#03BDC5"
                      : "rgba(2, 190, 207, 0.7)",
                  fontSize: "18px",
                  ".MuiListItemIcon-root": {
                    color:
                      location.pathname === "/network-settings"
                        ? "#03BDC5"
                        : "rgba(2, 190, 207, 0.7)",
                  },
                }}
                component={Link}
                to="/network-settings"
              >
                <ListItemIcon>
                  <WifiIcon />
                </ListItemIcon>
                <ListItemText primary="Network Settings" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            onClick={handleRelayClick}
            sx={{
              color:
                location.pathname.startsWith("/relay-") ||
                location.pathname.startsWith("/proxy")
                  ? "#03BDC5"
                  : "rgba(2, 190, 207, 0.7)",
              fontSize: "18px",
              ".MuiListItemIcon-root": {
                color:
                  location.pathname.startsWith("/relay-") ||
                  location.pathname.startsWith("/proxy")
                    ? "#03BDC5"
                    : "rgba(2, 190, 207, 0.7)",
              },
            }}
          >
            <ListItemIcon>
              <SettingsInputComponentIcon />
            </ListItemIcon>
            <ListItemText primary="Relay" />
            {openRelay ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openRelay} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{
                  pl: 4,
                  color:
                    location.pathname === "/relay-settings"
                      ? "#03BDC5"
                      : "rgba(2, 190, 207, 0.7)",
                  fontSize: "18px",
                  ".MuiListItemIcon-root": {
                    color:
                      location.pathname === "/relay-settings"
                        ? "#03BDC5"
                        : "rgba(2, 190, 207, 0.7)",
                  },
                }}
                component={Link}
                to="/relay-settings"
              >
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary="Relay Settings" />
              </ListItemButton>
              <ListItemButton
                sx={{
                  pl: 4,
                  color:
                    location.pathname === "/relay-family"
                      ? "#03BDC5"
                      : "rgba(2, 190, 207, 0.7)",
                  fontSize: "18px",
                  ".MuiListItemIcon-root": {
                    color:
                      location.pathname === "/relay-family"
                        ? "#03BDC5"
                        : "rgba(2, 190, 207, 0.7)",
                  },
                }}
                component={Link}
                to="/relay-family"
              >
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary="Relay Family" />
              </ListItemButton>
              <ListItemButton
                sx={{
                  pl: 4,
                  color:
                    location.pathname === "/proxy-settings"
                      ? "#03BDC5"
                      : "rgba(2, 190, 207, 0.7)",
                  fontSize: "18px",
                  ".MuiListItemIcon-root": {
                    color:
                      location.pathname === "/proxy-settings"
                        ? "#03BDC5"
                        : "rgba(2, 190, 207, 0.7)",
                  },
                }}
                component={Link}
                to="/proxy-settings"
              >
                <ListItemIcon>
                  <PublicIcon />
                </ListItemIcon>
                <ListItemText primary="Proxy Settings BETA" />
              </ListItemButton>
            </List>
          </Collapse>

          <ListItemButton
            onClick={handleSystemClick}
            sx={{
              color:
                location.pathname.startsWith("/password-settings") ||
                location.pathname.startsWith("/logs")
                  ? "#03BDC5"
                  : "rgba(2, 190, 207, 0.7)",
              fontSize: "18px",
              ".MuiListItemIcon-root": {
                color:
                  location.pathname.startsWith("/password-settings") ||
                  location.pathname.startsWith("/logs")
                    ? "#03BDC5"
                    : "rgba(2, 190, 207, 0.7)",
              },
            }}
          >
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText primary="System" />
            {openSystem ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openSystem} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{
                  pl: 4,
                  color:
                    location.pathname === "/password-settings"
                      ? "#03BDC5"
                      : "rgba(2, 190, 207, 0.7)",
                  fontSize: "18px",
                  ".MuiListItemIcon-root": {
                    color:
                      location.pathname === "/password-settings"
                        ? "#03BDC5"
                        : "rgba(2, 190, 207, 0.7)",
                  },
                }}
                component={Link}
                to="/password-settings"
              >
                <ListItemIcon>
                  <VpnKeyIcon />
                </ListItemIcon>
                <ListItemText primary="Change Password" />
              </ListItemButton>
              <ListItemButton
                sx={{
                  pl: 4,
                  color:
                    location.pathname === "/logs"
                      ? "#03BDC5"
                      : "rgba(2, 190, 207, 0.7)",
                  fontSize: "18px",
                  ".MuiListItemIcon-root": {
                    color:
                      location.pathname === "/logs"
                        ? "#03BDC5"
                        : "rgba(2, 190, 207, 0.7)",
                  },
                }}
                component={Link}
                to="/logs"
              >
                <ListItemIcon>
                  <ListAltIcon />
                </ListItemIcon>
                <ListItemText primary="Logs" />
              </ListItemButton>
            </List>
          </Collapse>

          {isMobile && (
            <ListItemButton
              onClick={handleRebootDialogOpen}
              sx={{
                marginTop: "5px",
                color: "rgba(2, 190, 207, 0.7)",
                fontSize: "18px",
                ".MuiListItemIcon-root": {
                  color: "rgba(2, 190, 207, 0.7)",
                },
                marginBottom: "0.5rem",
              }}
            >
              <ListItemIcon>
                <RestartAltIcon />
              </ListItemIcon>
              Reboot Relay
            </ListItemButton>
          )}
          {flash ? ( isMobile && (
      <ListItemButton
      onClick={handleUndo}
      sx={{
        marginTop: "5px",
        color: "rgba(2, 190, 207, 0.7)",
        fontSize: "18px",
        ".MuiListItemIcon-root": {
          color: "rgba(2, 190, 207, 0.7)",
        },
        marginBottom: "0.5rem",
      }}
    >
      <ListItemIcon>
        <UndoIcon />
      </ListItemIcon>
      Undo Changes
    </ListItemButton>
    )) : null}
        </List>
      </Drawer>

      <AppBar
        position="fixed"
        style={{
          width: isSmallScreen ? `100%` : `calc(100% - ${drawerWidth})`,
          marginLeft: isSmallScreen ? 0 : drawerWidth,
          backgroundColor: "#0A121E",
          borderBottom: `1px solid rgba(2, 190, 207, 0.2)`,
        }}
      >
        <Toolbar
          style={{
            justifyContent: "space-between",
            paddingTop: "1rem",
            height: "10vh",
            paddingLeft: isSmallScreen ? "2rem" : "50px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {isSmallScreen && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: "block" } }}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                variant="h6"
                noWrap
                marginBottom={0}
                sx={{ fontSize: "24px" }}
              >
                Welcome to your{" "}
                <span style={{ color: "#03BDC5" }}>Relay</span>
              </Typography>
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ color: "#aaa", fontSize: "16px" }}
                marginTop={0}
              >
                Status: <span style={{ color: "#03BDC5" }}>{status}</span>
              </Typography>
            </Box>
          </Box>

          {!isMobile && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* <MaterialUISwitch /> */}
              {flash ? (
                <>
                  <Typography sx={{ color: "red" , lineHeight:'1.3'}}>
                    Settings have changed, reboot required
                  </Typography>
                  <Button
                    component="label"
                    variant="contained"
                    onClick={handleUndo}
                    sx={{
                      background: "none",
                      alignContent: "center",
                      justifyContent: "center",
                      textAlign:'center',
                      lineHeight: checkButtonArrangement ? '1.1rem' : '1.8rem',
                      color: "inherit",
                      marginLeft: "1rem",
                      border: "1px solid #03BDC5",
                      ":hover": {
                        backgroundColor: "#03BDC5",
                        boxShadow: "0 3px 5px 2px rgba(2, 190, 207, .3)",
                      },
                    }}
                  >
                    Undo Changes
                  </Button>
                </>
              ) : null}
              <Button
                component="label"
                variant="contained"
                onClick={handleRebootDialogOpen}
                sx={{
                  background: flash ? "red" : "none",
                  color: flash ? "red" : "inherit",
                  marginLeft: "1rem",
                  // padding: checkButtonArrangement ? "0.5rem 1rem" : "0.5rem 1.3rem",
                  lineHeight: checkButtonArrangement ? '2.2rem' : '1.8rem',
                  border: flash ? "1px solid red" : "1px solid #03BDC5",
                  animation: flash ? `${flashAnimation} 1s infinite` : "none",
                  ":hover": {
                    backgroundColor: flash ? "red" : "#03BDC5",
                    boxShadow: flash
                      ? "0 3px 5px 2px rgba(255, 0, 0, .3)"
                      : "0 3px 5px 2px rgba(2, 190, 207, .3)",
                  },
                }}
                startIcon={<RestartAltIcon />}
              >
                Reboot
              </Button>
            </Box>
          )}
        </Toolbar>
      </AppBar>

      <main
        style={{
          flexGrow: 1,
          padding: !isMobile ? "150px 40px 40px 60px" : "120px 20px 10px 20px",
          boxShadow: "0 30px 40px rgba(0,0,0,.1)",
          border: "0px",
          height: "82vh",
          position: 'relative', // Add this to make the overlay div position absolute relative to this container
        }}
      >

<Dialog
        open={sessionExpiredDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="session-expired-dialog-title"
        aria-describedby="session-expired-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#0A121E",
            color: "#fff",
            borderRadius: 2,
            border: "1px solid #007275",
          },
        }}
      >
        <DialogTitle id="session-expired-dialog-title">
          {"Please Login"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            id="session-expired-dialog-description"
            sx={{ color: "#fff" }}
          >
            Your session may have expired. Please login again to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: "#03BDC5" }}>
            Go to Login
          </Button>
        </DialogActions>
      </Dialog>

      
        
      {status && (status.toLowerCase().includes("updating") ||
      status.toLowerCase().includes("connecting") ||
      status.toLowerCase().includes("starting") ||
  status.toLowerCase().includes("installing") ||
  status.toLowerCase().includes("removing") ||
  status.toLowerCase().includes("welcome") ||
  status.toLowerCase().includes("usb key") ||
  status.toLowerCase().includes("rebooting")) && (
    <Overlay>
      <CircularProgress
        sx={{ color: "#03BDC5", marginBottom: "1rem", marginRight: "1rem" }}
      />
      <Typography variant="h4">{status}</Typography>
    </Overlay>
  )}
        <div
          style={{
            display: "flex",
            height: "100%",
            minWidth: "90%",
          }}
        >
          {children}
        </div>
      </main>
      
      <Dialog
        open={rebootDialogOpen}
        onClose={() => setRebootDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "#0A121E",
            color: "#fff",
            borderRadius: 2,
            border: "1px solid #007275",
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Reboot Relay?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#fff" }}
          >
            {setFlash ? "You are about to reboot your relay and apply changed settings. This will temporarily disrupt network connectivity. Do you want to proceed?" :
            "You are about to reboot your relay. This will temporarily disrupt network connectivity. Do you want to proceed?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleRebootDialogClose} sx={{ color: "#03BDC5" }}>
            Cancel
          </Button>
          <Button onClick={handleReboot} sx={{ color: "#03BDC5" }}>
            Reboot
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MainContainer;
