import React from "react";
import { Typography, Box } from "@mui/material";

// Custom component for label and value
const InfoItem = ({ label, value, blur }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding={"5px"}
    >
      <Typography
        variant="subtitle2"
        style={{
          color: "#fff",
          marginRight: 8,
          marginLeft: 20,
          maxWidth: "40%",
        }}
      >
        {label}:
      </Typography>
      <Typography
        variant="h6"
        style={{
          color: "#03BDC5",
          fontSize: "18px",
          marginRight: 21,
          textAlign: "right",
          maxWidth: "55%",
          filter: blur && label === 'Serial ID' ? 'blur(10px)' : 'none'
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};

export default InfoItem;
