import axios from "axios";

export const fetchData = async (qNum) => {
    try {
      const response = await axios.get('./ZZ_shieldajax.php?rev=1&q=4'); // Update with actual path  
      const extractedData = response.data;

      // console.log(extractedData);
      
      return extractedData;

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  export const fetchDataShield = async () => {
    try {
      const response = await axios.get('./ZZ_shieldajax.php?rev=1&q=7'); // Update with actual path  
      const extractedData = response.data;

      // console.log(extractedData);
      
      return extractedData;

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

export const writeData = async (qNum, qVal) => {
    try {
        const response = await axios.get('./ZZ_shieldajax.php', {
          params: {
            // rev: WEB_VER_ajax, // Make sure this variable is defined and holds the correct value
            rev: '1',
            q: 5
          }
        }); // Update with actual path  
        

  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
}