import { React, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  Stack,
  Switch,
  FormControlLabel,
  ToggleButton,
  ToggleButtonGroup,
  TextField,
  Box,
  Button,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import MainContainer from "../components/MainContainer";
import { styled, useTheme } from "@mui/material";
import { fetchDataShield } from "../api/getRelayData";
import { useShieldData } from "../context/ShieldData";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { useShieldData } from "../context/ShieldData";

const boxStyle = {
  padding: "2rem",
  color: "#fff",
  backgroundColor: "#0A121E",
  borderRadius: 4,
  border: "1px solid #007275",
  marginBottom: "1rem", // Added margin bottom for spacing between rows
  boxShadow: "0px 0px 1px 0px #d4d4d4",
};

const ProxySettings = ({ openSettings, setOpenSettings }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [wifiMode, setWifiMode] = useState("");
  const [ethernetEnabled, setEthernetEnabled] = useState(false);
  const [wifiSSID, setWifiSSID] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");
  const [apSSID, setApSSID] = useState("");
  const [apPassword, setApPassword] = useState("");
  const [openDialog, setOpenDialog] = useState(false); // State to control Dialog open/close
  const [showWifiPassword, setShowWifiPassword] = useState(false);
  const [showApPassword, setShowApPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [oldNetwork, setOldNetwork] = useState("");

  const [relayEnabled, setRelayEnabled] = useState("");

  const isRelayEnabled = enableFields === "on";
  const [enableFields, setEnableFields] = useState("");
  const [relayExe, setRelayExe] = useState("");
  const [relayRestart, setRelayRestart] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [walletId, setWalletId] = useState("");
  const [port, setPort] = useState("");
  const [nftID, setNftID] = useState("");
  const [network, setNetwork] = useState("");
  const [password, setPassword] = useState("");

  const [wifiSSIDError, setWifiSSIDError] = useState("");
  const [wifiPasswordError, setWifiPasswordError] = useState("");
  const [apSSIDError, setApSSIDError] = useState("");
  const [apPasswordError, setApPasswordError] = useState("");

  const [vpnPurge, setVPNPurge] = useState("");

  const navigate = useNavigate();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [proxyEth0, setProxyEth0] = useState("");
  const [proxyWlan0, setProxyWlan0] = useState("");
  const [proxyAP, setProxyAP] = useState("");
  const [canSetProxyETH, setCanSetProxyETH] = useState(true);
  const [canSetProxyWLAN, setCanSetProxyWLAN] = useState(true);
  const [canSetProxyAP, setCanSetProxyAP] = useState(true);
  const [networkSetting, setNetworkSetting] = useState("");

  const [wlanIP, setWlanIP] = useState("");
  const [ethernetIP, setEthernetIP] = useState("");
  const [networkIP, setNetworkIP] = useState("");

  const [vpnStatus, setVPNStatus] = useState("");
  const [encStatus, setEncStatus] = useState("");
  const [ethUP, setETHUP] = useState("");
  const [wlanUP, setWlanUP] = useState("");
  const [apMode, setApMode] = useState("");
  const [jsonPost, setJsonPost] = useState("");

  const [startingProxyEth0, setStartingProxyEth0] = useState("");
  const [startingProxyWlan0, setStartingProxyWlan0] = useState("");
  const [startingProxyAP, setStartingProxyAP] = useState("");

  const isXSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const textContainerStyle = {
    width: isXSmallScreen ? "100%" : isMobileScreen ? "100%" : "50%",
    wordWrap: "break-word",
  };

  const { shieldData, error } = useShieldData();

  useEffect(() => {
    if (shieldData) {
      setTimeout(() => {
        // Extract data from shieldData after a delay of 1 second
        let eData = shieldData;
        setWifiMode(
          shieldData["Settings"]["ShieldSetting_03"] === "off"
            ? "off"
            : shieldData["Settings"]["ShieldSetting_04"]
        );
        setEthernetEnabled(shieldData["Settings"]["ShieldSetting_09"] === "on");
        setNewPassword(shieldData["Settings"]["ShieldSetting_02"]);
        setWifiSSID(shieldData["Settings"]["ShieldSetting_05"]);
        setWifiPassword(shieldData["Settings"]["ShieldSetting_06"]);
        setApSSID(shieldData["Settings"]["ShieldSetting_07"]);
        setApPassword(shieldData["Settings"]["ShieldSetting_08"]);
        setRelayEnabled(shieldData["Settings"]["ShieldSetting_10"]);
        setRelayExe(shieldData["Settings"]["ShieldSetting_11"]);
        setRelayRestart(shieldData["Settings"]["ShieldSetting_12"]);
        setNickname(shieldData["Settings"]["ShieldSetting_13"]);
        setEmail(shieldData["Settings"]["ShieldSetting_14"]);
        setWalletId(shieldData["Settings"]["ShieldSetting_15"]);
        setPort(shieldData["Settings"]["ShieldSetting_16"]);
        setEnableFields(shieldData["Settings"]["ShieldSetting_10"]);
        setNetwork(shieldData["Settings"]["ShieldSetting_03"]);
        setOldNetwork(shieldData["Settings"]["ShieldSetting_04"]);
        setPassword(shieldData["Settings"]["ShieldSetting_01"]);
        setVPNPurge(shieldData["Settings"]["ShieldSetting_17"]);
        setProxyEth0(shieldData["Settings"]["ShieldSetting_18"]);
        setProxyWlan0(shieldData["Settings"]["ShieldSetting_19"]);
        setProxyAP(shieldData["Settings"]["ShieldSetting_20"]);
        setWlanIP(shieldData["SHIELD_ipwlan0"]);
        setEthernetIP(shieldData["SHIELD_ipeth0"]);
        setNetworkIP(shieldData["SHIELD_ippublic"]);
        setVPNStatus(shieldData["SHIELD_vpn"]);
        setEncStatus(shieldData["SHIELD_enc"]);
        setETHUP(shieldData["SHIELD_eth0"]);
        setWlanUP(shieldData["SHIELD_wlan0"]);
        setApMode(shieldData["SHIELD_wlan0_lbl"]);
        setJsonPost(shieldData["Settings"]["ShieldSetting_21"]);
        setStartingProxyEth0(shieldData["Settings"]["ShieldSetting_18"]);
        setStartingProxyWlan0(shieldData["Settings"]["ShieldSetting_19"]);
        setStartingProxyAP(shieldData["Settings"]["ShieldSetting_20"]);
        setNftID(shieldData["Settings"]["ShieldSetting_22"]);

        setIsLoading(false);
      }, 200); // Delay set to 1 second
    } else if (error) {
      setIsLoading(false);
    }
  }, [shieldData["SHIELD_enc"], shieldData["SHIELD_vpn"], error]); // Add dependencies array if shieldData and error are dependencies of this effect

  const getProxyStatusText = (
    proxyType,
    proxy,
    initialProxy,
    isEnabled,
    ipAddress,
    port
  ) => {
    const shouldShowConnectionInfo = !(
      proxyType === "Wi-Fi Proxy" && (apMode === "HOTSPOT:" || network === 'off')
    );

    if (proxy === "on") {
      return shouldShowConnectionInfo
        ? `When ${proxyType} is available. Connect to ${ipAddress}:${port}`
        : `${proxyType} will be available when connected to Wi-Fi`;
    } else {
      return initialProxy === proxy
        ? `${proxyType} is not enabled.`
        : `${proxyType} is not enabled.`;
    }
  };

  const handleToggleChangeETH = (event, newMode) => {
    const anonIsRunning = vpnStatus === "Running" && encStatus === "Online";
    const ethIsUp = ethUP === "Up";

    // if (!ethIsUp || !anonIsRunning) {
    //   // setProxyEth0("off");
    //   setCanSetProxyETH(true);
    //   return;
    // }
    if (newMode !== null) {
      setProxyEth0(newMode);
      // Update server with new WiFi mode
    }
  };

  const handleToggleChangeWLAN = (event, newMode) => {
    const anonIsRunning = vpnStatus === "Running" && encStatus === "Online";
    const wlanIsUp = wlanUP === "Up" && apMode === "WI-FI:";

    // if (!wlanIsUp || !anonIsRunning) {
    //   // setProxyWlan0("off");
    //   setCanSetProxyWLAN(true);
    //   return;
    // }
    if (newMode !== null) {
      // if (proxyAP === "on" && proxyWlan0 === "off") {
      //   return;
      // }
      setProxyWlan0(newMode);
      // Update server with new WiFi mode
    }
  };

  const handleToggleChangeAP = (event, newMode) => {
    const anonIsRunning = vpnStatus === "Running" && encStatus === "Online";
    const canHotspot = wlanUP === "Up" && apMode === "HOTSPOT:";

    // if (!canHotspot || !anonIsRunning) {
    //   // setProxyAP("off");
    //   setCanSetProxyAP(true);
    //   return;
    // }
    if (newMode !== null) {
      // if (proxyAP === "off" && proxyWlan0 === "on") {
      //   return;
      // }
      setProxyAP(newMode);
      // Update server with new WiFi mode
    }
  };

  const saveRequestParams = () => {
    let params = new URLSearchParams();

    const addParam = (key, value) => {
      if (value !== "") {
        params.append(key, value);
      }
    };

    addParam("01", password);
    addParam("02", newPassword);
    addParam("03", network);
    addParam("10", relayEnabled);
    addParam("11", relayExe);
    addParam("12", relayRestart);
    addParam("13", nickname);
    addParam("14", email);

    if (walletId !== "") {
      addParam("15", walletId);
    }

    addParam("16", port);
    addParam("09", ethernetEnabled ? "on" : "off");
    addParam("04", wifiMode);
    addParam("05", wifiSSID);
    addParam("06", wifiPassword);
    addParam("07", apSSID);
    addParam("08", apPassword);
    addParam("17", vpnPurge);
    addParam("18", proxyEth0);
    addParam("19", proxyWlan0);
    addParam("20", proxyAP);
    addParam("21", jsonPost);
    addParam("22", nftID);

    return params;
  };

  const handleReboot = async () => {
    try {
      // Assuming '1' is the query parameter that triggers a reboot
      const response = await axios.get("./ZZ_shieldajax.php", {
        params: {
          // rev: WEB_VER_ajax, // Make sure this variable is defined and holds the correct value
          rev: "1",
          q: "1",
        },
      });
      // console.log(response.data);
      // Assuming you want to reload to home after the reboot action
      if (newPassword !== "") {
        try {
          const logout = await axios.get(`./ZZ_logout.php`);
          localStorage.removeItem("cacheCleared");
          navigate("/");
        } catch (error) {}
      }

      window.location.reload();
    } catch (error) {
      console.error("Reboot failed:", error);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const params = saveRequestParams();
    // console.log(params.toString());
    try {
      const response = await axios.get(
        `./ZZ_shieldajax.php?rev=1&q=5&${params.toString()}`
      );
      setOpenDialog(false);
    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          // Handle unauthorized error specifically
          navigate("/");
        } else {
          // Handle other errors
          throw error;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
    }
  };

  const handleApplyClick = () => {
    setOpenDialog(true); // Open the dialog
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
  };

  if (isLoading) {
    return (
      <MainContainer
        title="Loading..."
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#03BDC5" }} />
        </Box>
      </MainContainer>
    );
  }

  if (error) {
    return (
      <MainContainer
        title="Error"
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h6" color="error">
            Failed to load data. Please try again later.
          </Typography>
        </Box>
      </MainContainer>
    );
  }

  return (
    <MainContainer
      title="My Dashboard"
      openSettings={openSettings}
      setOpenSettings={setOpenSettings}
    >
      <style>
        {`
          @keyframes glowing {
            0% { opacity: 1; }
            50% { opacity: 0.5; }
            100% { opacity: 1; }
          }
          .glowing-beta {
            animation: glowing 1s infinite;
            color: #03BDC5;
          }
        `}
      </style>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            // Targeting the Dialog's Paper component for the background
            backgroundColor: "#0A121E", // Example darker color; replace with your actual color
            color: "#fff", // Making text color white
            borderRadius: 2, // Optional: Adding a border radius
            border: "1px solid #007275", // Optional: Adding a border
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Save Changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#fff" }}
          >
            Saving changes requires a reboot to apply. Do you want to proceed
            with saving settings?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: "#03BDC5" }}>
            Cancel
          </Button>
          <Button onClick={handleSave} sx={{ color: "#03BDC5" }}>
            Save
          </Button>
          {/* <Button onClick={handleSubmit} sx={{ color: "#03BDC5" }} autoFocus>
            Apply and Reboot
          </Button> */}
        </DialogActions>
      </Dialog>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Header */}
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#ffffff" }}
            marginBottom={0}
          >
            Proxy Settings <span className="glowing-beta">Beta</span>
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={handleApplyClick}
              sx={{
                bgcolor: "#03BDC5",
                marginLeft: "1rem",
                height: "43px",
                width: "129px",
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>

        {/* Subtitle */}
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ color: "#aaa" }}
          marginBottom={2}
        >
          <div style={textContainerStyle}>
            A SOCKS5 Proxy will be activated when the selected interface becomes
            available.
          </div>
        </Typography>

        {/* Grid Layout for Boxes */}
        {/* {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>} */}

        <Grid container spacing={2}>
          {" "}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              {/* <Typography variant="h5" gutterBottom> */}
              {/* {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>} */}
              {/* Proxy Settings */}
              {/* </Typography> */}

              <Box sx={{ mt: isSmallScreen ? 2 : 1 }}>
                <Typography gutterBottom>ETHERNET PROXY:</Typography>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between", // This should ensure space between the toggle and text
                    alignItems: "center",
                    backgroundColor: "#0A121EFF",
                    borderWidth: "1px",
                    borderColor: "#0A121EFF",
                    padding: theme.spacing(1), // Ensure there's padding around the contents
                    width: "100%", // Ensuring the paper takes full width
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={proxyEth0}
                    exclusive
                    onChange={handleToggleChangeETH}
                    aria-label="Ethernet Proxy Mode"
                    // disabled={!canSetProxyETH} // Disable the whole group if canSetProxyETH is false
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      border: 1,
                      borderRadius: 2,
                      borderColor: canSetProxyETH ? "#03BDC5" : "#595959", // Grey border when disabled
                      height: "43px",
                      "& .MuiToggleButtonGroup-grouped": {
                        borderRadius: 2,
                        margin: "2px",
                        "&.Mui-selected": {
                          backgroundColor: canSetProxyETH
                            ? "#03BDC5"
                            : "#595959", // Grey background when disabled
                          color: canSetProxyETH ? "white" : "darkgrey", // Dark grey text when disabled
                          "&:hover": {
                            backgroundColor: canSetProxyETH
                              ? "#03BDC5"
                              : "#595959", // Keep the grey color on hover when disabled
                          },
                        },
                        "&:not(.Mui-selected)": {
                          color: canSetProxyETH ? "#b1e0f1ff" : "darkgrey", // Dark grey text when not selected and disabled
                          "&:hover": {
                            backgroundColor: canSetProxyETH
                              ? "#03BDC5"
                              : "#595959", // Grey background on hover when disabled
                          },
                        },
                      },
                    }}
                  >
                    <ToggleButton value="off">Off</ToggleButton>
                    <ToggleButton value="on">On</ToggleButton>
                  </ToggleButtonGroup>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "#fff",
                      marginLeft: "auto",
                      textAlign: "right",
                    }}
                  >
                    {getProxyStatusText(
                      "Ethernet Proxy",
                      proxyEth0,
                      startingProxyEth0,
                      canSetProxyETH,
                      ethernetIP,
                      9050
                    )}
                  </Typography>
                </Paper>
              </Box>

              <Box sx={{ mt: isSmallScreen ? 2 : 1 }}>
                <Typography gutterBottom>WIFI PROXY:</Typography>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between", // This should ensure space between the toggle and text
                    alignItems: "center",
                    backgroundColor: "#0A121EFF",
                    borderWidth: "1px",
                    borderColor: "#0A121EFF",
                    padding: theme.spacing(1), // Ensure there's padding around the contents
                    width: "100%", // Ensuring the paper takes full width
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={proxyWlan0}
                    exclusive
                    onChange={handleToggleChangeWLAN}
                    aria-label="WiFi Proxy Mode"
                    // disabled={!canSetProxyWLAN} // Disable the whole group if canSetProxyWLAN is false
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      border: 1,
                      borderRadius: 2,
                      borderColor: canSetProxyWLAN ? "#03BDC5" : "#595959", // Grey border when disabled
                      height: "43px",
                      "& .MuiToggleButtonGroup-grouped": {
                        borderRadius: 2,
                        margin: "2px",
                        "&.Mui-selected": {
                          backgroundColor: canSetProxyWLAN
                            ? "#03BDC5"
                            : "#595959", // Grey background when disabled
                          color: canSetProxyWLAN ? "white" : "darkgrey", // Dark grey text when disabled
                          "&:hover": {
                            backgroundColor: canSetProxyWLAN
                              ? "#03BDC5"
                              : "#595959", // Keep the grey color on hover when disabled
                          },
                        },
                        "&:not(.Mui-selected)": {
                          color: canSetProxyWLAN ? "#b1e0f1ff" : "darkgrey", // Dark grey text when not selected and disabled
                          "&:hover": {
                            backgroundColor: canSetProxyWLAN
                              ? "#03BDC5"
                              : "#595959", // Grey background on hover when disabled
                          },
                        },
                      },
                    }}
                  >
                    <ToggleButton value="off">Off</ToggleButton>
                    <ToggleButton value="on">On</ToggleButton>
                  </ToggleButtonGroup>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "#fff",
                      marginLeft: "auto",
                      textAlign: "right",
                    }}
                  >
                    {getProxyStatusText(
                      "Wi-Fi Proxy",
                      proxyWlan0,
                      startingProxyWlan0,
                      canSetProxyWLAN,
                      wlanIP,
                      9050
                    )}
                  </Typography>
                </Paper>
              </Box>

              <Box sx={{ mt: isSmallScreen ? 2 : 1 }}>
                <Typography gutterBottom>HOTSPOT PROXY:</Typography>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between", // This should ensure space between the toggle and text
                    alignItems: "center",
                    backgroundColor: "#0A121EFF",
                    borderWidth: "1px",
                    borderColor: "#0A121EFF",
                    padding: theme.spacing(1), // Ensure there's padding around the contents
                    width: "100%", // Ensuring the paper takes full width
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={proxyAP}
                    exclusive
                    onChange={handleToggleChangeAP}
                    aria-label="AP Proxy Mode"
                    // disabled={!canSetProxyAP} // Disable the whole group if canSetProxyAP is false
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      border: 1,
                      borderRadius: 2,
                      borderColor: canSetProxyAP ? "#03BDC5" : "#595959", // Grey border when disabled
                      height: "43px",
                      "& .MuiToggleButtonGroup-grouped": {
                        borderRadius: 2,
                        margin: "2px",
                        "&.Mui-selected": {
                          backgroundColor: canSetProxyAP
                            ? "#03BDC5"
                            : "#595959", // Grey background when disabled
                          color: canSetProxyAP ? "white" : "darkgrey", // Dark grey text when disabled
                          "&:hover": {
                            backgroundColor: canSetProxyAP
                              ? "#03BDC5"
                              : "#595959", // Keep the grey color on hover when disabled
                          },
                        },
                        "&:not(.Mui-selected)": {
                          color: canSetProxyAP ? "#b1e0f1ff" : "darkgrey", // Dark grey text when not selected and disabled
                          "&:hover": {
                            backgroundColor: canSetProxyAP
                              ? "#03BDC5"
                              : "#595959", // Grey background on hover when disabled
                          },
                        },
                      },
                    }}
                  >
                    <ToggleButton value="off">Off</ToggleButton>
                    <ToggleButton value="on">On</ToggleButton>
                  </ToggleButtonGroup>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "#fff",
                      marginLeft: "auto",
                      textAlign: "right",
                    }}
                  >
                    {getProxyStatusText(
                      "Hotspot Proxy",
                      proxyAP,
                      startingProxyAP,
                      canSetProxyAP,
                      "10.42.0.1",
                      9050
                    )}
                  </Typography>
                </Paper>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </MainContainer>
  );
};

export default ProxySettings;
