import { React, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  Box,
  useTheme,
  useMediaQuery,
  CircularProgress,
  Button,
} from "@mui/material";
import InfoItem from "../components/InfoItem";
import CustomInfoItem from "../components/CustomInfoItem";
import CustomInfoItemMobile from "../components/CustomInfoItemMobile";
import InfoItemIdentification from "../components/InfoItemIdentification";
import InfoItemCopyable from "../components/InfoItemCopyable";
import InfoItemMasked from "../components/InfoItemWithMaskedIP";
import MainContainer from "../components/MainContainer";
import { useShieldData } from "../context/ShieldData";
import version from '../version.json';

const Home = ({ openSettings, setOpenSettings }) => {
  const { shieldData, error } = useShieldData();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [isLoading, setIsLoading] = useState(true);
  const [blurred, setBlurred] = useState(
    localStorage.getItem("blurred") === "false" ? false : true
  );
  const [revealButton, setRevealButton] = useState(
    localStorage.getItem("blurred") === "false" ? "HIDE" : "REVEAL"
  );
  const [webVersion, setWebVersion] = useState(null);

  const [networkStatus, setNetworkStatus] = useState({
    wlanStatus: "UNKNOWN",
    ethernetStatus: "UNKNOWN",
    vpnStatus: "UNKNOWN",
    encryptionStatus: "UNKNOWN",
    networkOverall: "UNKNOWN",
    socTemp: "UNKNOWN",
    gpuTemp: "UNKNOWN",
    fanStatus: "UNKNOWN",
    relayStatus: "UNKNOWN",
    buildTime: "UNKNOWN",
    buildDate: "UNKNOWN",
    buildVersion: "UNKNOWN",
    vpnVersion: "UNKNOWN",
    osVersion: "UNKNOWN",
    vpnOrPort: "UNKNOWN",
    serial: "UNKNOWN",
    wlanIP: "UNKNOWN",
    ethernetIP: "UNKNOWN",
    networkIP: "UNKNOWN",
    vpnFP: "UNKNOWN",
    pubkey: "UNKNOWN",
    webVER: "UNKNOWN",
    atecID: "UNKNOWN",
    serviceName: "UNKNOWN",
    walletID: "UNKNOWN",
  });

  const boxStyleBottom = {
    padding: "2rem",
    color: "#fff",
    backgroundColor: "#0A121E",
    borderRadius: 4,
    border: "1px solid #007275",
    marginBottom: "1rem",
    boxShadow: "0px 0px 1px 0px #d4d4d4",
    minHeight: isSmallScreen ? "auto" : "250px",
    height: isSmallScreen ? "auto" : "250px",
  };

  const boxStyle = {
    padding: "2rem",
    color: "#fff",
    backgroundColor: "#0A121E",
    borderRadius: 4,
    border: "1px solid #007275",
    marginBottom: "1rem",
    boxShadow: "0px 0px 1px 0px #d4d4d4",
    minHeight: isSmallScreen ? "auto" : "390px",
    height: isSmallScreen ? "auto" : "390px",
  };

  const handleReveal = () => {
    if (revealButton === "REVEAL") {
      setRevealButton("HIDE");
      localStorage.setItem("blurred", "false");
    } else {
      setRevealButton("REVEAL");
      localStorage.setItem("blurred", "true");
    }
    setBlurred(!blurred);
  };

  useEffect(() => {
    setTimeout(() => {
    if (shieldData) {
      const vpnOrPort = shieldData["SHIELD_vpnorport"] || "UNKNOWN";
      const formattedVpnOrPort = vpnOrPort.includes("FARU") ? vpnOrPort.replace("FARU", "FA,RU") : vpnOrPort;
      setNetworkStatus({
        wlanStatus: shieldData["SHIELD_wlan0"] || "UNKNOWN",
        wlanMode: shieldData["SHIELD_wlan0_lbl"] || "UNKNOWN",
        ethernetStatus: shieldData["SHIELD_eth0"] || "UNKNOWN",
        vpnStatus: shieldData["SHIELD_vpn"] || "UNKNOWN",
        encryptionStatus: shieldData["SHIELD_enc"] || "UNKNOWN",
        networkOverall: shieldData["SHIELD_network"] || "UNKNOWN",
        socTemp: shieldData["SHIELD_soctemp"] || "UNKNOWN",
        gpuTemp: shieldData["SHIELD_gputemp"] || "UNKNOWN",
        fanStatus: shieldData["SHIELD_fan"] || "UNKNOWN",
        relayStatus: shieldData["SHIELD_state"] || "UNKNOWN",
        buildTime: shieldData["SHIELD_build_time"] || "UNKNOWN",
        buildDate: shieldData["SHIELD_build_date"] || "UNKNOWN",
        buildVersion: shieldData["SHIELD_build_version"] || "UNKNOWN",
        vpnVersion: shieldData["SHIELD_ver_vpn"] || "UNKNOWN",
        osVersion: shieldData["SHIELD_ver_os"] || "UNKNOWN",
        vpnOrPort: formattedVpnOrPort,
        serial: `0x${shieldData["SHIELD_serial"] || "UNKNOWN"}`,
        wlanIP: shieldData["SHIELD_ipwlan0"] || "UNKNOWN",
        ethernetIP: shieldData["SHIELD_ipeth0"] || "UNKNOWN",
        networkIP: shieldData["SHIELD_ippublic"] || "UNKNOWN",
        vpnFP: shieldData["SHIELD_fngrprnt"] || "UNKNOWN",
        pubkey: shieldData["SHIELD_devicePUBKEY"] || "UNKNOWN",
        // webVER: shieldData["SHIELD_web"] || "UNKNOWN",
        webVER: `${version.major}.${version.minor}.${version.patch}` || "UNKNOWN",
        atecID: shieldData["SHIELD_atecid"] || "UNKNOWN",
        serviceName: "UNKNOWN",
        walletID: shieldData["Settings"]["ShieldSetting_15"] || "UNKNOWN",
      });
      
      setIsLoading(false);
    } else if (error) {
      setTimeout(() => {
        setIsLoading(false);  
      }, 8000);
    }
    }, 1000);
   
  }, [shieldData, error]);

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  if (isLoading) {
    return (
      <MainContainer
        title="Loading..."
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#03BDC5" }} />
        </Box>
      </MainContainer>
    );
  }

  if (error) {
    return (
      <MainContainer
        title="Error"
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h6" color="error">
            Failed to load data. Please try again later.
          </Typography>
        </Box>
      </MainContainer>
    );
  }

  return (
    <MainContainer
      title="My Dashboard"
      openSettings={openSettings}
      setOpenSettings={setOpenSettings}
    >
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#fff", marginBottom: 0 }}
          >
            Home
          </Typography>
          <Button
            variant="contained"
            sx={{ bgcolor: "#03BDC5", height: "30px", width: "90px" }}
            onClick={handleReveal}
          >
            {revealButton}
          </Button>
        </div>
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ color: "#aaa" }}
          marginBottom={2}
        >
          View your relay information here
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Typography variant="h5" gutterBottom>
                Network
              </Typography>
              <InfoItem
                label="Wireless"
                value={networkStatus.wlanMode + " " + networkStatus.wlanStatus}
              />
              <InfoItemMasked
                label="IP Address"
                value={networkStatus.wlanIP}
                blur={blurred}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="Ethernet" value={networkStatus.ethernetStatus} />
              <InfoItemMasked
                label="IP Address"
                value={networkStatus.ethernetIP}
                blur={blurred}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="Internet" value={networkStatus.networkOverall} />
              <InfoItemMasked
                label="IP Address"
                value={networkStatus.networkIP}
                blur={blurred}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem
                label={"Client Service"}
                value={networkStatus.vpnStatus}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem
                label={"Client Status"}
                value={networkStatus.encryptionStatus}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              {!isMobileScreen && (
                <CustomInfoItem
                  label="Reachability"
                  value={networkStatus.vpnOrPort}
                />
              )}
              {isMobileScreen && (
                <CustomInfoItemMobile
                  label="Reachability"
                  value={networkStatus.vpnOrPort}
                />
              )}
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Typography variant="h5" gutterBottom>
                Versions
              </Typography>
              <InfoItem label={"Client"} value={networkStatus.vpnVersion} />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="App" value={networkStatus.buildVersion} />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="Web" value={networkStatus.webVER} />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="OS" value={networkStatus.osVersion} />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="Build Date" value={networkStatus.buildDate} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyleBottom}>
              <Typography variant="h5" gutterBottom>
                Status
              </Typography>
              <InfoItem label="SOC" value={networkStatus.socTemp} />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="GPU" value={networkStatus.gpuTemp} />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="FAN" value={networkStatus.fanStatus} />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItem label="Relay" value={networkStatus.relayStatus} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyleBottom}>
              <Typography variant="h5" gutterBottom>
                Identification
              </Typography>
              <InfoItemCopyable
                label={"Fingerprint"}
                value={networkStatus.vpnFP}
                blur={blurred}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItemCopyable
                label="Serial ID"
                value={networkStatus.serial}
                blur={blurred}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItemCopyable
                label="Public Key"
                value={networkStatus.pubkey}
                blur={blurred}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItemCopyable
                label="Board ID"
                value={networkStatus.atecID}
                blur={blurred}
              />
              <Divider variant="middle" style={{ backgroundColor: "#555" }} />
              <InfoItemCopyable
                label="Wallet ID"
                value={networkStatus.walletID}
                blur={blurred}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </MainContainer>
  );
};

export default Home;
