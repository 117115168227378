import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
// import Settings from './pages/Settings'; // Placeholder for the Settings component
import Logs from './pages/Logs'; // Placeholder for the Logs component
import NetworkSettings from './pages/NetworkSettings';
import RelaySettings from './pages/RelaySettings';
import PasswordSettings from './pages/PasswordSettings';
import Login from './pages/Login';
import FamilySettingsPage from './pages/Family';
import ProxySettings from './pages/ProxySettings';

import { ShieldDataProvider } from './context/ShieldData'; // Correct path to ShieldData.js

function App() {
  const [openSettings, setOpenSettings] = useState(false);

  return (
    <ShieldDataProvider>
      <Router>
          {/* Define your routes here */}
          <Routes>
            <Route path="/home" element={<Home openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
            <Route path="/" element={<Login openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
            <Route path="/logs" element={<Logs openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
            <Route path="/network-settings" element={<NetworkSettings openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
            <Route path="/relay-settings" element={<RelaySettings openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
            <Route path="/password-settings" element={<PasswordSettings openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
            <Route path="/relay-family" element={<FamilySettingsPage openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
            <Route path="/proxy-settings" element={<ProxySettings openSettings={openSettings} setOpenSettings={setOpenSettings}/>} />
          </Routes>
      </Router>
    </ShieldDataProvider>
  );
}

export default App;
