import React, { useState, useEffect, useRef } from "react";
import {
  Typography,
  Paper,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Box,
  CircularProgress,
} from "@mui/material";
import { fetchDataShield } from "../api/getRelayData";
import MainContainer from "../components/MainContainer";

const Logs = ({ openSettings, setOpenSettings }) => {
  const [logType, setLogType] = useState("2");
  const [logContent, setLogContent] = useState("");
  const [logBin, setLogBin] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const paperRef = useRef(null); // Ref for Paper component

  const getFileName = (path) => {
    if (typeof path === "string") {
      const parts = path.split("/");
      return parts.pop();
    }
    return "";
  };

  useEffect(() => {
    const extractData = async () => {
      let extractedData = await fetchDataShield();
      const logBinRaw = getFileName(
        extractedData["Settings"]["ShieldSetting_11"]
      );
      setLogBin(logBinRaw);
      setIsLoading(false);
    };
    extractData();
  }, []);

  useEffect(() => {
    const fetchLogContent = async () => {
      if (logType) {
        let params = new URLSearchParams();
        params.append("logfile", logType);
        params.append("logBin", logBin);
        try {
          const response = await fetch(
            `./ZZ_shieldajax.php?rev=1&q=6&${params.toString()}`
          );
          if (response.ok) {
            const data = await response.text();
            setLogContent(data);
          } else {
            console.error("Failed to fetch log content");
            setLogContent("Failed to load log content.");
          }
        } catch (error) {
          console.error("Error fetching log content:", error);
          setLogContent("Error loading log content.");
        }
      } else {
        setLogContent("");
      }
    };

    fetchLogContent();
    const intervalId = setInterval(fetchLogContent, 500); // Fetch every 5 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [logType, logBin]);

  useEffect(() => {
    if (paperRef.current) {
      paperRef.current.scrollTop = paperRef.current.scrollHeight;
    }
  }, [logContent]);

  const handleLogTypeChange = (event) => {
    setLogType(event.target.value);
  };

  const paperStyle = {
    backgroundColor: "#0A121EFF",
    color: "#abebff",
    padding: "20px",
    minHeight: "100%",
    // height: "400px",
    maxHeight: "100%",
    fontFamily: "monospace",
    whiteSpace: "pre-wrap",
    overflow: "auto",
    width: "100%", // Ensure the paper takes full width of its container
    boxSizing: "border-box", // Include padding in the element's width and height
    border: "1px solid #007275",
  };

  if (isLoading) {
    return (
      <MainContainer
        title="Loading..."
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress sx={{ color: "#03BDC5" }} />
        </Box>
      </MainContainer>
    );
  }

  return (
    <MainContainer
      title="My Dashboard"
      openSettings={openSettings}
      setOpenSettings={setOpenSettings}
      sx={{ overflow: "hidden"}}
    >
      <Box sx={{ width: "100%" }}>
        <Box sx={{ width: "100%", height:"85%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{ color: "#ffffff" }}
              marginBottom={0}
            >
              Logs
            </Typography>

            <FormControl
              variant="standard"
              sx={{
                // marginBottom: 2,
                minWidth: 250,
                "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
                  color: "gray",
                },
              }}
            >
              <InputLabel
                id="log-type-select-label"
                sx={{
                  color: "#d4d4d4",
                  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
                    color: "#aaa",
                  },
                }}
              >
                Log to dump
              </InputLabel>
              <Select
                labelId="log-type-select-label"
                id="log-type-select"
                value={logType}
                onChange={handleLogTypeChange}
                label="Log to dump"
                sx={{
                  color: "white",
                  "&:before": {
                    borderBottomColor: "rgba(0, 177, 183, 0.72)",
                  },
                  "& label": {
                    color: "rgba(0, 177, 183, 0.72)",
                  },
                  "& label.Mui-focused": {
                    color: "rgba(0, 177, 183, 0.72)",
                  },
                  "&:after": {
                    borderBottomColor: "rgba(0, 177, 183, 0.72)",
                  },
                  "& .MuiSvgIcon-root": {
                    color: "white",
                  },
                  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
                    color: "gray",
                  },
                }}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      bgcolor: "black",
                      color: "white",
                    },
                  },
                }}
              >
                <MenuItem value="0">{"/var/log/bootstrap.log"}</MenuItem>
                <MenuItem value="1">{`/var/log/${logBin}/notices.log`}</MenuItem>
                <MenuItem value="2">
                  {"/home/relayup/dev/logs/00_logs.log"}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ color: "#aaa", marginBottom: 2 }}
          >
            View all your log information here
          </Typography>

          <Paper elevation={3} sx={paperStyle} ref={paperRef}>
            {logType && <div>{logContent}</div>}
          </Paper>
        </Box>
      </Box>
    </MainContainer>
  );
};

export default Logs;
