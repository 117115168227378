import { React, useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Paper,
  TextField,
  Box,
  Button,
  Alert,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import MainContainer from "../components/MainContainer";
import axios from "axios";
import { fetchDataShield } from "../api/getRelayData";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// Define a custom style for the TextField
const CustomTextField = styled(TextField)({
  "& label": {
    color: "#595959", // Default label color
  },
  "& label.Mui-focused": {
    color: "#595959", // Label color when the TextField is focused
  },
  "& label.Mui-disabled": {
    color: "#595959", // Label color when the TextField is disabled
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Default outline color
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Outline color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Outline color when input is focused
    },
    "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
      borderColor: "#aaa", // Outline color when input is disabled
    },
  },
  "& .MuiInputBase-input": {
    color: "white", // Input text color
  },
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "#595959", // Text color when input is disabled
  },
});

const boxStyle = {
  padding: "2rem",
  color: "#fff",
  backgroundColor: "#0A121E",
  borderRadius: 4,
  border: "1px solid #007275",
  marginBottom: "1rem", // Added margin bottom for spacing between rows
  boxShadow: "0px 0px 1px 0px #d4d4d4",
};

const PasswordSettings = ({ openSettings, setOpenSettings }) => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(""); // State to handle error messages
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [wifiMode, setWifiMode] = useState("");
  const [ethernetEnabled, setEthernetEnabled] = useState(false);
  const [wifiSSID, setWifiSSID] = useState("");
  const [wifiPassword, setWifiPassword] = useState("");
  const [apSSID, setApSSID] = useState("");
  const [apPassword, setApPassword] = useState(""); // State to control Dialog open/close
  const [showWifiPassword, setShowWifiPassword] = useState(false);
  const [showApPassword, setShowApPassword] = useState(false);
  const [relayEnabled, setRelayEnabled] = useState("");
  const [networkSetting, setNetworkSetting] = useState("");

  const isRelayEnabled = enableFields === "on";
  const [enableFields, setEnableFields] = useState("");
  const [relayExe, setRelayExe] = useState("");
  const [relayRestart, setRelayRestart] = useState("");
  const [nickname, setNickname] = useState("");
  const [email, setEmail] = useState("");
  const [walletId, setWalletId] = useState("");
  const [port, setPort] = useState("");
  const [nftID, setNftID] = useState("");
  const [VPNPurge, setVPNPurge] = useState("");
  const [proxyEth0, setProxyEth0] = useState("");
  const [proxyWlan0, setProxyWlan0] = useState("");
  const [proxyAP, setProxyAP] = useState("");
  const [jsonPost, setJsonPost] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    const extractData = async () => {
      let extractedData = await fetchDataShield();
      // Assuming extractedData has the required keys
      setNetworkSetting(extractedData["Settings"]["ShieldSetting_03"]);
      setWifiMode(extractedData["Settings"]["ShieldSetting_04"]);
      setWifiSSID(extractedData["Settings"]["ShieldSetting_05"]);
      setWifiPassword(extractedData["Settings"]["ShieldSetting_06"]);
      setApSSID(extractedData["Settings"]["ShieldSetting_07"]);
      setApPassword(extractedData["Settings"]["ShieldSetting_08"]);
      setEthernetEnabled(
        extractedData["Settings"]["ShieldSetting_09"] === "on"
      );
      setRelayEnabled(extractedData["Settings"]["ShieldSetting_10"]);
      setEnableFields(extractedData["Settings"]["ShieldSetting_10"]);
      setRelayExe(extractedData["Settings"]["ShieldSetting_11"]);
      setRelayRestart(extractedData["Settings"]["ShieldSetting_12"]);
      setNickname(extractedData["Settings"]["ShieldSetting_13"]);
      setEmail(extractedData["Settings"]["ShieldSetting_14"]);
      setWalletId(extractedData["Settings"]["ShieldSetting_15"]);
      setPort(extractedData["Settings"]["ShieldSetting_16"]);
      setVPNPurge(extractedData["Settings"]["ShieldSetting_17"]);
      setProxyEth0(extractedData["Settings"]["ShieldSetting_18"]);
      setProxyWlan0(extractedData["Settings"]["ShieldSetting_19"]);
      setProxyAP(extractedData["Settings"]["ShieldSetting_20"]);
      setJsonPost(extractedData["Settings"]["ShieldSetting_21"]);

      setIsLoading(false);
    };

    extractData();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const createRequestParams = () => {
    let params = new URLSearchParams();
    params.append("01", currentPassword);
    params.append("02", newPassword);
    return params;
  };

  const saveRequestParams = () => {
    let params = new URLSearchParams();
  
    const addParam = (key, value) => {
      if (value !== "") {
        params.append(key, value);
      }
    };
  
    addParam("03", networkSetting);
    addParam("01", currentPassword);
    addParam("02", newPassword);
    addParam("10", relayEnabled);
    addParam("11", relayExe);
    addParam("12", relayRestart);
    addParam("13", nickname);
    addParam("14", email);
    
    if (walletId !== "") {
      addParam("15", walletId);
    }
  
    addParam("16", port);
    addParam("09", ethernetEnabled ? "on" : "off");
    addParam("04", wifiMode);
    addParam("05", wifiSSID);
    addParam("06", wifiPassword);
    addParam("07", apSSID);
    addParam("08", apPassword);
    addParam("17", VPNPurge);
    addParam("18", proxyEth0);
    addParam("19", proxyWlan0);
    addParam("20", proxyAP);
    addParam("21", jsonPost);
    addParam("22", nftID);
  
    return params;
  };
  
  const handleSave = async (e) => {
    e.preventDefault();

    setCurrentPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");

    if (
      currentPassword === "" ||
      newPassword === "" ||
      confirmPassword === ""
    ) {
      if (currentPassword === "") {
        setCurrentPasswordError("Please fill in this field");
      }
      if (newPassword === "") {
        setNewPasswordError("Please fill in this field");
      }
      if (confirmPassword === "") {
        setConfirmPasswordError("Please fill in this field");
      }
      setOpenDialog(false);
      return;
    }

    if (newPassword !== confirmPassword) {
      setNewPasswordError("New password and confirm password do not match.");
      setConfirmPasswordError(
        "New password and confirm password do not match."
      );
      setOpenDialog(false);
      return;
    }

    const illegalCharacters = ["#", "'", "\\", "=", "´", '*'];
    if (illegalCharacters.some((char) => newPassword.includes(char))) {
      setNewPasswordError("Illegal characters detected (#, ', \\, =, ´, *)");
      setConfirmPasswordError("Illegal characters detected (#, ', \\, =, ´, *)");
      setOpenDialog(false);
      return;
    }

    if ((await verifyCurrentPassword()) === false) {
      setCurrentPasswordError("Current password is incorrect.");
      setOpenDialog(false);
      return;
    }

    if (newPassword.length > 32 || confirmPassword.length > 32) {
      setNewPasswordError(
        "Please make sure password is not longer than 32 characters"
      );
      setConfirmPasswordError(
        "Please make sure password is not longer than 32 characters"
      );
      setOpenDialog(false);
      return;
    }

    const params = saveRequestParams();
    // console.log(params.toString());
    try {
      const response = await axios.get(
        `./ZZ_shieldajax.php?rev=1&q=5&${params.toString()}`
      );
      setOpenDialog(false);
      // handleReboot();
    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          // Handle unauthorized error specifically
          navigate("/");
        } else {
          // Handle other errors
          throw error;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error:", error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
      }
    }
  };

  const handleReboot = async () => {

    try {
      const res = await axios.get(`./ZZ_shieldajax.php?rev=1&q=12`);
      try {
      
        // Assuming '1' is the query parameter that triggers a reboot
        const response = await axios.get("./ZZ_shieldajax.php", {
          params: {
            // rev: WEB_VER_ajax, // Make sure this variable is defined and holds the correct value
            rev: "1",
            q: "1",
          },
        });
        // console.log(response.data);
        // Assuming you want to reload to home after the reboot action
  
        try {
          const logout = await axios.get(`./ZZ_logout.php`);
          localStorage.removeItem('cacheCleared');
  
          // Assuming the logout was successful, you can then proceed with any cleanup or navigation
          // setOpenDialog(false);
          setCurrentPassword("");
          setNewPassword("");
          setConfirmPassword("");
          navigate("/");
        } catch (error) {
          // Handle any errors that occur during logout
          setError("Something went wrong. Please try again.");
        }
        window.location.reload();
      } catch (error) {
        console.error("Reboot failed:", error);
      }
    } catch (error) {
      console.error('failed');
    }
    
  };

  const handleApplyClick = () => {
    setOpenDialog(true); // Open the dialog
  };

  const handleDialogClose = () => {
    setOpenDialog(false); // Close the dialog
  };

  const verifyCurrentPassword = async () => {
    // setIsLoading(true); // Start loading
    const formData = new FormData();
    formData.append("check_login", "1");
    formData.append("login_password", currentPassword);

    try {
      const response = await fetch("./ZZ_login2.php", {
        method: "POST",
        body: formData,
      });
      
      // console.log("Data: ", response);
      const data = await response.json(); // Assuming the response is JSON
      
      // console.log("Data: ", data);
      // console.log(data);
      if (response.ok) {
        if (data.success) {
          // console.log(data)
          return true;
        } else {
          setCurrentPasswordError("Current password is incorrect.");
          return false;
        }
      } else {
        console.error("Failed to verify current password");
        setError("Something went wrong. Please try again.");
        return false;
      }
    } catch (error) {
      console.error("Error verifying current password:", error);
      setError("Something went wrong. Please try again.");
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setCurrentPasswordError("");
    setNewPasswordError("");
    setConfirmPasswordError("");

    if (
      currentPassword === "" ||
      newPassword === "" ||
      confirmPassword === ""
    ) {
      if (currentPassword === "") {
        setCurrentPasswordError("Please fill in this field");
      }
      if (newPassword === "") {
        setNewPasswordError("Please fill in this field");
      }
      if (confirmPassword === "") {
        setConfirmPasswordError("Please fill in this field");
      }
      setOpenDialog(false);
      return;
    }
    if (newPassword !== confirmPassword) {
      setNewPasswordError("New password and confirm password do not match.");
      setConfirmPasswordError(
        "New password and confirm password do not match."
      );
      setOpenDialog(false);
      return;
    }

    if (newPassword.length > 31) {
      setNewPasswordError(
        "Password cannot be over 32 characters long."
      );
      setOpenDialog(false);
      return;
    }


    const illegalCharacters = ["#", "'", "\\", "=", "´"];
    if (illegalCharacters.some((char) => newPassword.includes(char))) {
      setNewPasswordError("Illegal characters detected (#, ', \\, =, ´)");
      setConfirmPasswordError("Illegal characters detected (#, ', \\, =, ´)");
      setOpenDialog(false);
      return;
    }

    if ((await verifyCurrentPassword()) === false) {
      setCurrentPasswordError("Current password is incorrect.");
      setOpenDialog(false);
      return;
    }

    if (newPassword.length > 32 || confirmPassword.length > 32) {
      setNewPasswordError(
        "Please make sure password is not longer than 32 characters"
      );
      setConfirmPasswordError(
        "Please make sure password is not longer than 32 characters"
      );
      setOpenDialog(false);
      return;
    }

    const params = saveRequestParams();

    try {
      const response = await axios.get(
        `./ZZ_shieldajax.php?rev=1&q=5&${params.toString()}`
      );

      setOpenDialog(false);

      setTimeout(() => {
        handleReboot();
      }, 1000);

    } catch (error) {
      if (error.response) {
        console.error("Error:", error.response.data);
        if (error.response.status === 401) {
          // Handle unauthorized error specifically
          navigate("/");
        } else {
          // Handle other errors
          throw error;
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.error("Error:", error.request);
        // console.log("Failed to update password. Please try again.");
        setError("Failed to update password. Please try again.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error:", error.message);
        // console.log("Some error here");
        setError("An error occurred. Please try again.");
      }
    }
  };

  return (
    <MainContainer
      title="My Dashboard"
      openSettings={openSettings}
      setOpenSettings={setOpenSettings}
    >
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{
          "& .MuiPaper-root": {
            // Targeting the Dialog's Paper component for the background
            backgroundColor: "#0A121E", // Example darker color; replace with your actual color
            color: "#fff", // Making text color white
            borderRadius: 2, // Optional: Adding a border radius
            border: "1px solid #007275", // Optional: Adding a border
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{"Save Changes?"}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#fff" }}
          >
            Saving changes requires a reboot to apply. Do you want to proceed with 
            saving settings?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} sx={{ color: "#03BDC5" }}>
            Cancel
          </Button>
          <Button onClick={handleSave} sx={{ color: '#03BDC5' }}>
          Save
        </Button>
          {/* <Button onClick={handleSubmit} sx={{ color: "#03BDC5" }} autoFocus>
            Apply and Reboot
          </Button> */}
        </DialogActions>
      </Dialog>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* Header */}
          <Typography
            variant="h4"
            gutterBottom
            style={{ color: "#ffffff" }}
            marginBottom={0}
          >
            Change Password
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              onClick={handleApplyClick}
              sx={{
                bgcolor: "#03BDC5",
                marginLeft: "1rem",
                height: "40px",
                width: "120px",
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>

        {/* Subtitle */}
        <Typography
          variant="subtitle1"
          gutterBottom
          style={{ color: "#aaa" }}
          marginBottom={2}
        >
          Change your relay password here
        </Typography>

        {/* Grid Layout for Boxes */}
        {error && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Grid container spacing={2}>
          {" "}
          {/* Changed to 2 for spacing between items */}
          {/* Network Box */}
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Paper style={boxStyle}>
              <Typography variant="h5" gutterBottom>
                {/* {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>} */}
                Change Password
              </Typography>
              <CustomTextField
                fullWidth
                label="CURRENT PASSWORD"
                variant="outlined"
                type={showPassword ? "text" : "password"}
                margin="normal"
                value={currentPassword}
                error={!!currentPasswordError}
                helperText={currentPasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={togglePasswordVisibility}
                        edge="end"
                        sx={{ color: "#aaa" }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { color: "white" },
                }}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <CustomTextField
                fullWidth
                label="NEW PASSWORD"
                type={showNewPassword ? "text" : "password"}
                variant="outlined"
                margin="normal"
                value={newPassword}
                error={!!newPasswordError}
                helperText={newPasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleNewPasswordVisibility}
                        edge="end"
                        sx={{ color: "#aaa" }}
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { color: "white" },
                }}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <CustomTextField
                fullWidth
                label="CONFIRM PASSWORD"
                type={showConfirmPassword ? "text" : "password"}
                variant="outlined"
                margin="normal"
                value={confirmPassword}
                error={!!confirmPasswordError}
                helperText={confirmPasswordError}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={toggleConfirmPasswordVisibility}
                        edge="end"
                        sx={{ color: "#aaa" }}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { color: "white" },
                }}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </MainContainer>
  );
};

export default PasswordSettings;
