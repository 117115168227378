import React, { useState, useEffect } from 'react';
import { Typography, TextField, Button, Box, styled, IconButton, InputAdornment, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useShieldData } from "../context/ShieldData";
import logo from "../logos/logosmall.png"

const CustomTextField = styled(TextField)({
  "& label": {
    color: "#595959", // Label color
  },
  "& label.Mui-focused": {
    color: "#595959", // Label color when the TextField is focused
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Default outline color
    },
    "&:hover fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Outline color on hover
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(0, 177, 183, 0.72)", // Outline color when input is focused
      transition: "0.2s", // Remove the hover transition
    },
  },
  "& .MuiInputBase-input": {
    color: "white", // Input text color
  },
});

const Login = () => {
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const { shieldData, error, sessionValid } = useShieldData();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [shieldDataReady, setShieldDataReady] = useState(false);

  const handleChange = (prop) => (event) => {
    setCredentials({ ...credentials, [prop]: event.target.value });
  };

  useEffect(() => {
    // Check if cache has been cleared
    if (!localStorage.getItem('cacheCleared')) {
      // Clear cache by reloading the page from the server
      localStorage.setItem('cacheCleared', 'true');
      window.location.reload(true);
    }
  }, []);

  useEffect(() => {
    if (shieldData && !error) {
      setShieldDataReady(true);
    }
  }, [shieldData, error]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  const handleLogin = async () => {
    setIsLoading(true); // Start loading

    const checkShieldDataReady = async () => {
      if (!shieldDataReady) {
        await wait(500); // Wait for 0.5 seconds before checking again
        return checkShieldDataReady();
      }
    };

    await checkShieldDataReady();

    await wait(1000); // Wait for 1 second

    // Wait for shieldDataReady to be true

    const formData = new FormData();
    formData.append('login_username', credentials.username);
    formData.append('login_password', credentials.password);

    try {
      const response = await fetch('./ZZ_login2.php', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json(); // Assuming the response is JSON
      if (response.ok) {
        if (data.success) {
          localStorage.removeItem('cacheCleared'); // Clear cache flag on successful login
          navigate('/home', { state: { fromRootPage: true } });
        } else {
          setLoginError(data.message || 'Login failed for an unknown reason');
        }
      } else {
        setLoginError('Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setLoginError('An error occurred during login. Please try again.');
    } finally {
      setIsLoading(false); // Stop loading regardless of the outcome
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
      textAlign={'center'}
    >
      <img src={logo} alt="Logo" style={{ marginBottom: '20px', maxWidth: '170px' }} />
      <Typography variant="h4" gutterBottom sx={{ color: 'white', mb: 4, justifyContent: 'center', alignItems: 'center', maxWidth: '500px' }}>
        Please login to access your <span style={{ color: '#03BDC5' }}>Relay</span>
      </Typography>

      {isLoading ? (
        <CircularProgress sx={{ color: '#03BDC5' }} />
      ) : (
        <>
          {loginError && <Typography color="error">{loginError}</Typography>}
          <Box
            component="form"
            onSubmit={(e) => e.preventDefault()} // Prevent form from causing page reload
            sx={{
              display: 'flex',
              flexDirection: 'column',
              '& .MuiTextField-root': { mb: 2 },
              '& .MuiButton-root': { mt: 1 },
            }}
          >
            <CustomTextField
              fullWidth
              variant="outlined"
              label="Password"
              autoFocus
              type={showPassword ? 'text' : 'password'}
              value={credentials.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={togglePasswordVisibility}
                      edge="end"
                      sx={{ color: '#aaa' }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
                style: { color: 'white' },
              }}
              onChange={handleChange('password')}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleLogin();
                }
              }}
            />
            <Button id='login_btn' variant="contained" onClick={handleLogin} sx={{ bgcolor: '#03BDC5' }}>
              Login
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Login;
